import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import asyncComponent from "../util/asyncComponent";
import { useFeature } from "context/FeatureProvider";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "@blocknote/react/style.css";
import Loader from "components/Loader";
import { useTenantContext } from "context/TenantContext";

function App(props) {
  const { match } = props;
  const featureFlags = useFeature();
  const { isTenantLoading } = useTenantContext();
  const isTribeActive = featureFlags.isTribeFeatureActive;
  const isTAXActive = featureFlags.allowToAddTAX;

  return (
    <div className="app-container fixed-drawer">
      <Sidebar />
      <div className="app-main-container">
        <div className="app-header">
          <Header />
        </div>
        <main className="app-main-content-wrapper">
          <div className="app-main-content">
            {!isTenantLoading ? (
              <Switch>
                <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import("./Dashboard"))} />
                {/* <Route path={`${match.url}/dashboard`} component={Dashboard} /> */}
                <Route path={`${match.url}/events/:eventId/ticket-types`} component={asyncComponent(() => import("./TicketTypes"))} />
                <Route path={`${match.url}/events`} component={asyncComponent(() => import("./Events"))} />
                <Route path={`${match.url}/discounts/:couponId`} component={asyncComponent(() => import("./DiscountCodes/DiscountCodesDetail"))} />
                <Route path={`${match.url}/discounts`} component={asyncComponent(() => import("./DiscountCodes"))} />
                <Route path={`${match.url}/videos`} component={asyncComponent(() => import("./Videos"))} />
                <Route path={`${match.url}/venues`} component={asyncComponent(() => import("./Venues"))} />
                <Route path={`${match.url}/boomerang`} component={asyncComponent(() => import("./Boomerang"))} />
                {isTAXActive && (
                  <Fragment>
                    <Route path={`${match.url}/taxes/tax-rates/:id`} component={asyncComponent(() => import("./Taxes/TaxRate"))} />
                    <Route path={`${match.url}/taxes`} component={asyncComponent(() => import("./Taxes"))} />
                  </Fragment>
                )}
                <Route path={`${match.path}/associates`} component={asyncComponent(() => import("./Associates"))} />
                <Route path={`${match.path}/sweepstakes/:sweepstakeId/actions`} component={asyncComponent(() => import("./Sweepstakes/SweepstakesActionsSetup"))} />
                <Route path={`${match.path}/sweepstakes`} component={asyncComponent(() => import("./Sweepstakes"))} />
                <Route path={`${match.path}/sweepstakes-list`} component={asyncComponent(() => import("./Sweepstakes/SweepstakesList"))} />
                <Route path={`${match.url}/fees`} component={asyncComponent(() => import("./Fees"))} />
                <Route path={`${match.url}/guests`} component={asyncComponent(() => import("./Guests"))} />
                <Route path={`${match.url}/questions`} component={asyncComponent(() => import("./Questions"))} />
                <Route path={`${match.url}/settings`} component={asyncComponent(() => import("./Settings"))} />
                <Route path={`${match.url}/affiliates/:affiliateId`} component={asyncComponent(() => import("./Affiliates/components/TrackingCodes"))} />
                <Route path={`${match.url}/affiliates`} component={asyncComponent(() => import("./Affiliates"))} />
                <Route path={`${match.url}/orders/import`} component={asyncComponent(() => import("./Orders/ImportOrder"))} />
                <Route path={`${match.url}/orders`} component={asyncComponent(() => import("./Orders"))} />
                {isTribeActive && (
                  <Fragment>
                    <Route path={`${match.url}/tribe/dashboard`} exact component={asyncComponent(() => import("./Tribe/Dashboard"))} />
                    <Route path={`${match.url}/tribe/registrations`} exact component={asyncComponent(() => import("./Tribe/Registrations"))} />
                    <Route path={`${match.url}/tribe/settings`} exact component={asyncComponent(() => import("./Tribe/Settings"))} />
                  </Fragment>
                )}
                <Route component={asyncComponent(() => import("components/Error404"))} />
              </Switch>
            ) : (
              <div className="h-100 flex justify-center">
                <div className="loader-view">
                  <Loader />
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default withRouter(App);
